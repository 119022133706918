
import "./App.css";
import Home from "./Home"
import Privacy from "./Privacy";
import {Route,Routes} from "react-router-dom"

function App() {
	return (
		<>
<Routes>
	<Route path="/" element={<Home/>}/>
	<Route path="/privacy" element={<Privacy/>}/>

</Routes>

{/* <Home/> */}

		</>
	);
}

export default App;
