import {Link} from "react-router-dom"
const Home = () => {
  return (
    <>
      <div className="row g-0 flex-row justify-content-center align-items-center mx-4"  style={{height:"95vh"}}>
        <div className="col-lg-6 mx-0 col-md-6  col-sm-12 text-center">
          <img
            className=" w-100 h-50"
            src="./2.jpg"
            style={{ "align-self": "center"}}
          />
        </div>
        <div className="col-lg-6 mx-0 col-md-6  col-sm-12 text-center">
        <img
            className=" w-25 h-25"
            src="./logo.png"
            // style={{ width: "20%", height: "10vh" }}
          />
          <p style={{"color": "#DE3163"}}><h1>Find Your Perfect Match</h1></p>
          {/* <div className="" style={{ margin: "40px 50px" }}>
            <h1>Community Vote &mdash;</h1>
            <h1>Bridging the partisan</h1>
            <h1>divide.</h1>
            <p>App available on Android and iOS</p>
            <div>
              <button
                type="button"
                className="px-3 btn my-1 text-white hover_button"
              >
                Available on Google Play Store
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-3 btn my-1 text-white hover_button"
              >
                Available on Apple App Store
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="row g-0 my-2">
        <div className="col-lg-6  col-md-6  col-sm-12 text-center ">
          <p className="m-0" style={{ "font-size": "0.85714286em" }}>
            © 2022 Kiiqui | All Rights Reserved
          </p>
        </div>
        <div className="col-lg-6 mx-0 col-md-6  col-sm-12 text-center m-0">
          <Link to="/privacy">Privacy Policy</Link>
            
          
        </div>
      </div>
    </>
  );
};
export default Home;
