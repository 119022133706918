import { useEffect, useState } from "react";
import axios from "axios";

const Privacy = () => {
  const [show, setshow] = useState([]);

  useEffect(() => {
    Privacyapi();
  }, []);

  const Privacyapi = async () => {
    const res = await axios.get(
      "https://api.kiiqui.com/users/getPrivacy"
    ); 
    setshow(res.data.data);
  };
  console.log(show);
  return (
    <>
      {/* <div className="text-center bg-success py-3 my-2">
      <h1 className="">Privacy&Policy</h1>
      </div> */}

      <div className="boder my-4 card ">
        <div className="row ">
          <div className="col-md-12 my-4 d-flex justify-content-center align-self-center text-uppercase">
            <h1 className=" mt-5"  style= {{"color": "white"}}>privacy Policy</h1>
          </div>
        </div>
      </div>

      <div className="container my-5 ">
        {/* {show[0]?.map((e, index) => {
          return (
            <> */}
              <div className="d-flex">
                {/* <h3 className="fs-4">{1}.</h3> */}
                <div className="mx-2" dangerouslySetInnerHTML={{__html:show?.privacyPolicy}}></div>
              </div>
            {/* </>
          );
        })} */}
      </div>
      
    </>
  );
};
export default Privacy;
